@import url("https://fonts.googleapis.com/css?family=Figtree:600,500,400,700|Manrope:700,600,800");
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}



:root {
  --white: rgba(255, 255, 255, 1);
}

@font-face {
  font-family: "Figma Hand-Bold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/6453a6d9a5563dc051e4bd06/fonts/home_fill0_wght400_grad0_opsz24.svg") format("");
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

html,
body {
  margin: 0px;
  height: 100%;
}

/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}

a {
  text-decoration: none;
}